import { Form, InputNumber, Radio, Typography } from "antd";
import CalculatorResults from "../shared/results";
import CalculatorShell from "../shared/shell";
import {
  dollarFormatter,
  numberFormatter,
  parser,
} from "../../constants/input-number-formats";
import { useEffect, useState } from "react";
import { useWatch } from "antd/lib/form/Form";
import CalculatorNotes from "../shared/notes";

const HomeOfficeDeduction = () => {
  const [form] = Form.useForm();
  const homeSquareFeet = useWatch("homeSquareFeet", form) || 0;
  const homeSquareFeetUsage = useWatch("homeSquareFeetUsage", form) || 0;
  const homeCostBasis = useWatch("homeCostBasis", form) || 0;
  const mortgageInterestRent = useWatch("mortgageInterestRent", form) || 0;
  const realEstateTaxes = useWatch("realEstateTaxes", form) || 0;
  const insurance = useWatch("insurance", form) || 0;
  const repairsMaintenance = useWatch("repairsMaintenance", form) || 0;
  const utilities = useWatch("utilities", form) || 0;
  const otherExpenses = useWatch("otherExpenses", form) || 0;

  const [otherExpenseDirection, setOtherExpenseDirection] = useState<
    "direct" | "indirect"
  >("indirect");
  const squareFootagePercentage = homeSquareFeetUsage / homeSquareFeet || 0;

  const otherExpensesTotal =
    otherExpenseDirection === "direct"
      ? otherExpenses
      : otherExpenses * squareFootagePercentage;

  const results = {
    simplified: Math.min(1500, homeSquareFeetUsage * 5),
    actual:
      squareFootagePercentage *
        (mortgageInterestRent +
          realEstateTaxes +
          insurance +
          repairsMaintenance +
          utilities) +
      squareFootagePercentage * (homeCostBasis / 27.5) +
      otherExpensesTotal,
  };

  const preferredMethod: "Simplified" | "Actual Expenses" | "Either" =
    results.simplified.toFixed(2) === results.actual.toFixed(2)
      ? "Either"
      : results.simplified > results.actual
      ? "Simplified"
      : "Actual Expenses";

  useEffect(() => {
    form.setFieldsValue({
      homeSquareFeet: Math.max(homeSquareFeet, homeSquareFeetUsage),
    });
  }, [homeSquareFeetUsage, homeSquareFeet, form]);

  return (
    <CalculatorShell title="Home Office Deduction">
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          homeSquareFeet: 0,
          homeSquareFeetUsage: 0,
          homeCostBasis: 0,
        }}
      >
        <Typography.Title level={5}>Direct Expenses</Typography.Title>
        <Form.Item
          label="Area used regularly and exclusively for business in square footage:"
          name="homeSquareFeetUsage"
        >
          <InputNumber
            min={0}
            formatter={(e) => numberFormatter(e)}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label="Total area of home in square footage:"
          name="homeSquareFeet"
        >
          <InputNumber
            min={homeSquareFeetUsage}
            parser={parser}
            formatter={(e) => numberFormatter(e)}
          />
        </Form.Item>
        <Form.Item label="Total cost basis of home:" name="homeCostBasis">
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Typography.Title level={5} style={{ marginTop: 24 }}>
          Indirect Expenses
        </Typography.Title>
        <Form.Item
          label="Mortgage interest or rent:"
          name="mortgageInterestRent"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item label="Real estate taxes:" name="realEstateTaxes">
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item label="Insurance:" name="insurance">
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item label="Repairs and maintenance:" name="repairsMaintenance">
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item label="Utilities:" name="utilities">
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item label="Other expenses type:">
          <Radio.Group
            onChange={(e) => setOtherExpenseDirection(e.target.value)}
            value={otherExpenseDirection}
            options={[
              { label: "Indirect", value: "indirect" },
              { label: "Direct", value: "direct" },
            ]}
          />
        </Form.Item>
        <Form.Item label="Other expenses" name="otherExpenses">
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item label="Other expenses deduction:">
          <InputNumber
            disabled
            value={(otherExpensesTotal || 0).toFixed(2)}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
      </Form>
      <div
        style={{
          marginTop: 24,
          display: !results.actual && !results.simplified ? "none" : "block",
        }}
      >
        <Typography.Title level={5}>
          {preferredMethod !== "Either" ? (
            <>
              Based on the inputs above, the <i>"{preferredMethod} Method"</i>{" "}
              would be more beneficial to utilize.
            </>
          ) : (
            <>
              Based on the inputs above, both the <i>"Simplified"</i> and{" "}
              <i>"Actual Expenses"</i> methods would produce the same benefits.
            </>
          )}
        </Typography.Title>
      </div>

      <CalculatorResults
        title={"Simplified Method"}
        style={{
          opacity: preferredMethod === "Actual Expenses" ? 0.5 : 1,
        }}
        results={[
          {
            title: "Current Year Tax Deduction",
            currencyResult: results.simplified,
          },
        ]}
      />
      <CalculatorResults
        title={"Actual Expenses Method"}
        style={{
          opacity: preferredMethod === "Simplified" ? 0.5 : 1,
        }}
        results={[
          {
            title: "Current Year Tax Deduction",
            currencyResult: results.actual,
          },
        ]}
      >
        <CalculatorNotes
          notes={[
            "Calculation assumes taxpayer has eligible income deduct entire home office expense in current year.",
            "Special rules apply for corporations, daycare providers and separate structures.",
          ]}
        />
      </CalculatorResults>
    </CalculatorShell>
  );
};

export default HomeOfficeDeduction;
