import { Form, InputNumber, Select } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { CURRENT_YEAR } from "../../constants/default-values";
import {
  dollarFormatter,
  parser,
  percentageFormatter,
} from "../../constants/input-number-formats";
import FederalTaxEngine from "../../types/federal-tax-engine";
import { currency } from "../../utils/currency";
import getSavedFederalTaxEngine from "../../utils/get-saved-federal-tax-engine";
import FederalTaxEngineEditor from "../shared/federal-tax-engine-editor";
import FteComparison from "../shared/fte-comparison";
import CalculatorNotes from "../shared/notes";
import CalculatorResults from "../shared/results";
import CalculatorShell from "../shared/shell";
const { Option } = Select;

const TaxDeferralVsTaxFreeIncome = () => {
  const [form] = Form.useForm();
  const filingYear = useWatch("filingYear", form);
  const investmentAmount = useWatch("investmentAmount", form);
  const expectedAnnualRoi = useWatch("expectedAnnualRoi", form);
  const distributionYears = useWatch("distributionYears", form);
  const mtrPrediction = useWatch("mtrPrediction", form);
  const [modalVisible, setModalVisible] = useState(false);
  const [preTax, setPreTax] = useState(new FederalTaxEngine());
  const [postEngine, setPostEngine] = useState(new FederalTaxEngine());
  const postContributionEstimatedDeferred =
    investmentAmount * (1 + expectedAnnualRoi / 100) ** distributionYears;
  const mtrOffset =
    mtrPrediction === "Same" ? 0.02 : mtrPrediction === "Lower" ? -0.05 : 0.07;
  const deferredIncomeTax =
    postContributionEstimatedDeferred *
    (preTax.results.federalIncomeTaxRate + mtrOffset);
  const taxDeferralResults = {
    currentYearCashFlow: -1 * investmentAmount,
    currentYearTaxFlow:
      preTax.results.estimatedTotalTaxes -
      postEngine.results.estimatedTotalTaxes,
    expectedFutureYearsCashFlow: postContributionEstimatedDeferred,
    expectedTax: -1 * deferredIncomeTax,
  };
  const taxFreeCurrentYearCashFlow =
    -1 * investmentAmount * (1 - preTax.results.federalIncomeTaxRate);
  const postContributionEstimatedTaxFree =
    -1 *
    taxFreeCurrentYearCashFlow *
    (1 + expectedAnnualRoi / 100) ** distributionYears;
  const taxFreeResults = {
    currentYearCashFlow: taxFreeCurrentYearCashFlow,
    currentYearTaxFlow:
      preTax.results.estimatedTotalTaxes -
      postEngine.results.estimatedTotalTaxes,
    expectedFutureYearsCashFlow: postContributionEstimatedDeferred,
    expectedTax: -1 * deferredIncomeTax,
  };

  useEffect(() => {
    let engine = getSavedFederalTaxEngine(filingYear);
    engine.calculate();
    setPreTax(engine);
  }, [modalVisible, filingYear]);

  useEffect(() => {
    if (investmentAmount >= 0) {
      let engine = getSavedFederalTaxEngine(filingYear);
      engine.federalTaxableIncome -= investmentAmount;
      engine.calculate();
      setPostEngine(engine);
    }
  }, [
    investmentAmount,
    expectedAnnualRoi,
    distributionYears,
    filingYear,
    modalVisible,
  ]);

  return (
    <CalculatorShell title="Tax Deferral vs. Tax Free Income">
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          filingYear: CURRENT_YEAR,
          investmentAmount: 0,
          expectedAnnualRoi: 8.0,
          distributionYears: 10,
          mtrPrediction: "Same",
        }}
      >
        <Form.Item label="Filing year:" name="filingYear">
          <Select placeholder="Select a filing year">
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <FederalTaxEngineEditor
            type="link"
            filingYear={filingYear}
            onModalViewChange={(visible) => setModalVisible(visible)}
          />
        </Form.Item>
        <Form.Item
          label="How do you expect your future MTR to compare to your current MTR?"
          name="mtrPrediction"
        >
          <Select
            style={{ minWidth: 250 }}
            placeholder="Choose an expectation:"
          >
            {["Lower", "Same", "Higher"].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={`Investment amount (maximum of ${currency.format(
            postEngine.magi
          )} based on ${filingYear} AGI):`}
          name="investmentAmount"
        >
          <InputNumber
            min={0}
            max={postEngine.magi}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label={`Years until qualified distributions (if less than 1 enter "0"):`}
          name="distributionYears"
        >
          <InputNumber min={0} max={50} step={1} />
        </Form.Item>
        <Form.Item
          label="Expected annual rate of return on investment:"
          name="expectedAnnualRoi"
        >
          <InputNumber
            min={1}
            max={30}
            step={0.25}
            formatter={percentageFormatter}
          />
        </Form.Item>
        <Form.Item label={`${filingYear} pre-investment federal tax:`}>
          <InputNumber
            min={0}
            formatter={dollarFormatter}
            parser={parser}
            disabled
            value={preTax.results.estimatedTotalTaxes}
          />
        </Form.Item>
        <Form.Item label="Post-contribution taxable income:">
          <InputNumber
            min={0}
            formatter={dollarFormatter}
            parser={parser}
            disabled
            value={preTax.federalTaxableIncome - investmentAmount}
          />
        </Form.Item>
        <Form.Item label={`${filingYear} post-contribution federal tax:`}>
          <InputNumber
            min={0}
            formatter={dollarFormatter}
            parser={parser}
            disabled
            value={postEngine.results.estimatedTotalTaxes}
          />
        </Form.Item>
        <Form.Item label="Post-contribution expected deferred taxable income growth:">
          <InputNumber
            min={0}
            formatter={dollarFormatter}
            parser={parser}
            disabled
            value={postContributionEstimatedDeferred}
          />
        </Form.Item>
        <Form.Item label="Expected tax on deferred taxable income:">
          <InputNumber
            min={0}
            formatter={dollarFormatter}
            parser={parser}
            disabled
            value={deferredIncomeTax}
          />
        </Form.Item>
        <Form.Item label="Post-contribution expected tax free taxable income growth:">
          <InputNumber
            min={0}
            formatter={dollarFormatter}
            parser={parser}
            disabled
            value={postContributionEstimatedTaxFree}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        title="Tax Deferral Tax Flow Calculation"
        results={[
          {
            title: "Current Year Cash Flow",
            content: currency.format(
              taxDeferralResults.currentYearCashFlow === 0
                ? 0
                : taxDeferralResults.currentYearCashFlow
            ),
          },
          {
            title: "Current Year Tax Flow",
            content: currency.format(taxDeferralResults.currentYearTaxFlow),
          },
          {
            title: "Expected Future Years Cash Flow",
            content: currency.format(
              taxDeferralResults.expectedFutureYearsCashFlow
            ),
          },
          {
            title: "Expected Tax on Deferred Taxable Income",
            content: currency.format(
              taxDeferralResults.expectedTax === 0
                ? 0
                : taxDeferralResults.expectedTax
            ),
          },
          {
            title: "Expected Net Tax Flow",
            boldTitle: true,
            content: currency.format(
              taxDeferralResults.currentYearCashFlow +
                taxDeferralResults.currentYearTaxFlow +
                taxDeferralResults.expectedFutureYearsCashFlow +
                taxDeferralResults.expectedTax
            ),
          },
        ]}
      />
      <CalculatorResults
        title="Tax Free Tax Flow Calculation"
        results={[
          {
            title: "Current Year Cash Flow",
            content: currency.format(
              taxFreeResults.currentYearCashFlow === 0
                ? 0
                : taxFreeResults.currentYearCashFlow
            ),
          },
          {
            title: "Current Year Tax Flow",
            content: currency.format(0),
          },
          {
            title: "Expected Future Years Cash Flow",
            content: currency.format(postContributionEstimatedTaxFree),
          },
          {
            title: "Expected Tax on Deferred Taxable Income",
            content: currency.format(0),
          },
          {
            title: "Expected Net Tax Flow",
            boldTitle: true,
            content: currency.format(
              taxFreeResults.currentYearCashFlow +
                postContributionEstimatedTaxFree
            ),
          },
        ]}
      >
        <CalculatorNotes
          notes={[
            `Calculation assumes taxpayer has eligible income deduct entire investment amount in ${filingYear}.`,
            "Potential future years tax flow represents tax free growth.",
            "Distributions from retirement accounts penalty free.",
          ]}
        />
        <FteComparison filingYear={filingYear} results={postEngine} />
      </CalculatorResults>
    </CalculatorShell>
  );
};

export default TaxDeferralVsTaxFreeIncome;
