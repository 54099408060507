import { Card } from "antd";
import {
  getTtfDiagnosis,
  TTtfDiagnosis,
} from "../../calculators/marginal-tax-rate/diagnosis";
import FederalTaxEngineEditor from "../../calculators/shared/federal-tax-engine-editor";
import DynamicLogo from "../dynamic-logo";

type Props = {
  userSavedFte: boolean;
  filingYear: number;
  diagnoses: Array<TTtfDiagnosis>;
  onFteModalViewChange(visible: boolean): void;
};

const TtfDiagnosis: React.FC<Props> = ({
  userSavedFte,
  filingYear,
  diagnoses,
  onFteModalViewChange,
}) => {
  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <h1>Teaching Tax Flow&#8482; Diagnosis</h1>
        <div
          style={{
            height: "auto",
            width: 100,
            marginTop: 12,
          }}
        >
          <DynamicLogo
            primaryColor={
              userSavedFte
                ? getTtfDiagnosis.get(diagnoses[1] as TTtfDiagnosis)?.color
                : undefined
            }
            secondaryColor={
              userSavedFte
                ? getTtfDiagnosis.get(diagnoses[0] as TTtfDiagnosis)?.color
                : undefined
            }
          />
        </div>
        {userSavedFte ? (
          <>
            <p style={{ width: "90%", textAlign: "center", marginTop: 12 }}>
              <b
                style={{
                  color: getTtfDiagnosis.get(diagnoses[0] as TTtfDiagnosis)
                    ?.color,
                }}
              >
                {diagnoses[0]}
              </b>
              :
              <span style={{ marginLeft: 3 }}>
                {
                  getTtfDiagnosis.get(diagnoses[0] as TTtfDiagnosis)
                    ?.description
                }
              </span>
            </p>
            <p style={{ width: "90%", textAlign: "center", marginTop: -6 }}>
              <b
                style={{
                  color: getTtfDiagnosis.get(diagnoses[1] as TTtfDiagnosis)
                    ?.color,
                }}
              >
                {diagnoses[1]}
              </b>
              :
              <span style={{ marginLeft: 3 }}>
                {
                  getTtfDiagnosis.get(diagnoses[1] as TTtfDiagnosis)
                    ?.description
                }
              </span>
            </p>
          </>
        ) : (
          <p style={{ width: "90%", textAlign: "center", display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' ,marginTop: 12 }}>
            To receive your diagnosis, please complete the{" "}
            <FederalTaxEngineEditor
              type="link"
              hideTitle
              filingYear={filingYear}
              onModalViewChange={onFteModalViewChange}
            />
          </p>
        )}
      </div>
    </Card>
  );
};

export default TtfDiagnosis;
