import { Col, Form, InputNumber, Row, Select } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { CURRENT_YEAR } from "../../constants/default-values";
import {
  dollarFormatter,
  parser,
  percentageFormatter,
} from "../../constants/input-number-formats";
import { getCompoundInterest } from "../../utils/compound-interest";
import { currency } from "../../utils/currency";
import CalculatorNotes from "../shared/notes";
import CalculatorShell from "../shared/shell";
import CalculatorResults from "../shared/results";
import FederalTaxEngineEditor from "../shared/federal-tax-engine-editor";
import getSavedFederalTaxEngine from "../../utils/get-saved-federal-tax-engine";
import FteComparison from "../shared/fte-comparison";
import FederalTaxEngine from "../../types/federal-tax-engine";
const { Option } = Select;

const RothConversion = () => {
  const [form] = useForm();
  const [preTax, setPreTax] = useState(0);
  const filingYear = useWatch("filingYear", form);
  const amount = useWatch("amount", form);
  const yearsUntilQualifiedDistributions = useWatch(
    "yearsUntilQualifiedDistributions",
    form
  );
  const expectedAnnualRoi = useWatch("expectedAnnualRoi", form);
  const [expectedCashFlow, setExpectedCashFlow] = useState(0);
  const [currentYearTaxFlow, setCurrentYearTaxFlow] = useState(0);
  const [engine, setEngine] = useState(new FederalTaxEngine());

  useEffect(() => {
    setExpectedCashFlow(
      getCompoundInterest(
        amount,
        expectedAnnualRoi,
        yearsUntilQualifiedDistributions
      )
    );
  }, [amount, yearsUntilQualifiedDistributions, expectedAnnualRoi]);

  useEffect(() => {
    if (filingYear) {
      const engine = getSavedFederalTaxEngine(filingYear);
      engine.federalTaxableIncome = engine.federalTaxableIncome += amount;
      engine.calculate();
      setEngine(engine);
      setCurrentYearTaxFlow(engine.results.estimatedTotalTaxes - preTax);
    }
  }, [amount, preTax, filingYear]);

  return (
    <>
      <CalculatorShell title="Roth Conversion">
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            filingYear: CURRENT_YEAR,
            amount: 0,
            yearsUntilQualifiedDistributions: 0,
            expectedAnnualRoi: 2.0,
          }}
        >
          <Form.Item label="Filing year:" name="filingYear">
            <Select>
              {[2021, 2022].map((year, index) => (
                <Option key={index} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Row>
              <Col xs={24} md={12} lg={8}>
                <FederalTaxEngineEditor
                  type="link"
                  onEstimatedTotalChange={(amount) => setPreTax(amount)}
                  filingYear={filingYear}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="Amount of Roth conversion:" name="amount">
            <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
          </Form.Item>
          <Form.Item
            label="Years until qualified distributions:"
            name="yearsUntilQualifiedDistributions"
          >
            <InputNumber min={0} step={1} />
          </Form.Item>
          <Form.Item
            label="Expected annual rate of return on investment:"
            name="expectedAnnualRoi"
          >
            <InputNumber
              min={2}
              max={25}
              step={0.25}
              formatter={percentageFormatter}
            />
          </Form.Item>
        </Form>
        <CalculatorResults
          results={[
            {
              title: "Current Year Tax Flow",
              content: currency.format(
                currentYearTaxFlow !== 0 ? currentYearTaxFlow * -1 : 0
              ),
            },
            {
              title: "Estimated Future Years Cash Flow",
              content: currency.format(expectedCashFlow),
            },
            {
              title: "Estimated Net Tax Flow",
              boldTitle: true,
              content: currency.format(expectedCashFlow - currentYearTaxFlow),
            },
          ]}
        >
          <FteComparison filingYear={filingYear} results={engine} />
          <CalculatorNotes
            notes={[
              "Calculations do not factor in changes to QBI.",
              "Potential future years tax flow represents tax free growth and penalty free distributions.",
            ]}
          />
        </CalculatorResults>
      </CalculatorShell>
    </>
  );
};

export default RothConversion;
