import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Button, Card, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useMediaQuery from "use-media-antd-query";
const RestrictedArea = () => {
  const [signInClick, setSignInClick] = useState(false);
  const { user, loginWithRedirect, isLoading, error } = useAuth0();
  const navigate = useNavigate();
  const colSize = useMediaQuery();

  useEffect(() => {
    if (error) {
      setSignInClick(false);
    }
  }, [error]);

  return (
    <Card
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        minHeight: "50vh",
        marginTop: "15vh",
        width: "50vw",
        marginLeft: "25vw",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <img
          src={`./teaching-tax-flow-${
            ["xs", "sm"].includes(colSize) ? "small" : "large"
          }.png`}
          alt=""
          style={{
            maxWidth: "75%",
            height: "auto",
            marginBottom: "4em",
          }}
        />
        <Alert
          type="error"
          description={
            <h3 style={{ maxWidth: 350 }}>
              You have attempted to reach a page that is currently restricted to
              administrators only.
            </h3>
          }
        />
        {/* {isLoading || signInClick ? (
          <Spin size="large" />
        ) : (
          <>
            {error ? (
              <Typography.Text type="danger" style={{ marginBottom: 12 }}>
                An error occured during authorization. Please try again.
              </Typography.Text>
            ) : null}
            <Button
              size="large"
              onClick={() => {
                setSignInClick(true);
                loginWithRedirect();
              }}
            >
              Sign in
            </Button>
          </>
        )} */}
      </div>
    </Card>
  );
};
export default RestrictedArea;
