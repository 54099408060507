const localStorageGet = (name: string) => {
  let data = localStorage.getItem(name);

  if (!data) return "";

  return JSON.parse(data);
};

const localStorageSave = (name: string, value: any) => {
  localStorage.setItem(name , JSON.stringify(value));
};

const localStorageDelete = (name: string) => {
  localStorage.removeItem(name);
};

export { localStorageGet, localStorageSave, localStorageDelete };
