import { PropsWithChildren } from "react";

const LowerOnly: React.FC<PropsWithChildren<any>> = ({ children }) => {
  if (process.env.REACT_APP_ENV === "production") {
    return null;
  }

  return children;
};

export default LowerOnly;
