import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="ttf-calculators.us.auth0.com"
      clientId="BglfW8Bahk1yzlWGuzupXPk0QfMLKrOZ"
      redirectUri={window.location.origin}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
);
