import { Form, InputNumber, Radio, Select } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { statuses, TFilingStatusCode } from "../../../constants/filing-status";
import {
  getMaximumAllowedContributions,
  HsaPlanType,
} from "../../../constants/hsa";
import {
  dollarFormatter,
  parser,
  percentageFormatter,
} from "../../../constants/input-number-formats";
import { CURRENT_YEAR } from "../../../constants/default-values";
import { getBaseFederalIncomeTax } from "../../../constants/base-federal-tax-calculator";
import CalculatorResults from "../../shared/results";
import CalculatorNotes from "../../shared/notes";
import { useEffect } from "react";
const { Option } = Select;

const HsaImmediateTaxDeductionCalculator = () => {
  const [form] = Form.useForm();
  const filingYear = useWatch<number>("filingYear", form);
  const filingStatusCode = useWatch<TFilingStatusCode>(
    "filingStatusCode",
    form
  );
  const hsaPlanType = useWatch<HsaPlanType>("hsaPlanType", form);
  const aged55 = useWatch<boolean>("aged55", form);
  const annualContribution = useWatch<number>("annualContribution", form);
  const taxableIncome = useWatch<number>("taxableIncome", form);
  const yearsUntilQualifiedDistributions = useWatch<number>(
    "yearsUntilQualifiedDistributions",
    form
  );
  const expectedAnnualRoi = useWatch<number>("expectedAnnualRoi", form);
  const maxContribution = getMaximumAllowedContributions(hsaPlanType, aged55);
  const preTaxRates = getBaseFederalIncomeTax(
    filingStatusCode,
    filingYear,
    taxableIncome
  );
  const preTax =
    preTaxRates.fixedTaxAmount +
    (taxableIncome - preTaxRates.floorAmount) * preTaxRates.rate;
  const postContributionTaxableIncome = Math.max(
    taxableIncome - annualContribution,
    0
  );
  const postTaxRates = getBaseFederalIncomeTax(
    filingStatusCode,
    filingYear,
    postContributionTaxableIncome
  );
  const postTax =
    postTaxRates.fixedTaxAmount +
    (postContributionTaxableIncome - postTaxRates.floorAmount) *
      postTaxRates.rate;
  const results = {
    currentYearCashFlow: annualContribution > 0 ? -1 * annualContribution : 0,
    currentYearTaxFlow: preTax - postTax,
    expectedFutureCashFlow:
      annualContribution *
      (1 + expectedAnnualRoi / 100) ** yearsUntilQualifiedDistributions,
  };

  useEffect(() => {
    if (filingStatusCode === "s") {
      form.setFieldsValue({ hsaPlanType: HsaPlanType.individual });
    }
  }, [filingStatusCode]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          filingYear: CURRENT_YEAR,
          filingStatusCode: "s",
          hsaPlanType: HsaPlanType.individual,
          aged55: false,
          annualContribution: 0,
          taxableIncome: 0,
          yearsUntilQualifiedDistributions: 0,
          expectedAnnualRoi: 1,
        }}
      >
        <Form.Item label="Filing year:" name="filingYear">
          <Select>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Filing status:" name="filingStatusCode">
          <Select>
            {statuses
              .filter((x) => x.code !== "qw")
              .map((d, index) => (
                <Option key={index} value={d.code}>
                  {d.label}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="HSA plan type:" name="hsaPlanType">
          <Select disabled={filingStatusCode === "s"}>
            {Object.values(HsaPlanType).map((planType, index) => (
              <Option key={index} value={planType}>
                {planType}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Age 55 or older at the end of the year?"
          name="aged55"
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Maximum HSA account contribution:">
          <InputNumber
            min={0}
            formatter={dollarFormatter}
            parser={parser}
            disabled
            value={maxContribution}
          />
        </Form.Item>
        <Form.Item
          label="Annual contribution amount:"
          name="annualContribution"
        >
          <InputNumber
            min={0}
            max={maxContribution}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item label="Taxable income" name="taxableIncome">
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label="Years until qualified distributions:"
          name="yearsUntilQualifiedDistributions"
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item
          label="Expected annual rate of return on investment:"
          name="expectedAnnualRoi"
        >
          <InputNumber
            min={1}
            max={20}
            step={0.25}
            formatter={percentageFormatter}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        showDivider
        results={[
          {
            title: "Current Year Cash Flow",
            currencyResult: results.currentYearCashFlow,
          },
          {
            title: "Current Year Tax Flow",
            currencyResult: results.currentYearTaxFlow,
          },
          {
            title: "Expected Future Years Cash Flow",
            currencyResult: results.expectedFutureCashFlow,
          },
          {
            title: "Expected Net Tax Flow",
            currencyResult:
              results.currentYearCashFlow +
              results.currentYearTaxFlow +
              results.expectedFutureCashFlow,
            boldTitle: true,
          },
        ]}
      >
        {" "}
        <CalculatorNotes
          notes={[
            "Calculations do not factor in deductions for QBI nor alternative minimum tax. ",
            "Maximum contributions based on current year.",
            "Potential future years tax flow represents tax free growth.",
          ]}
        />
      </CalculatorResults>
    </>
  );
};

export default HsaImmediateTaxDeductionCalculator;
