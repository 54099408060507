import { CURRENT_YEAR } from "./default-values";
import { TFilingStatusCode } from "./filing-status";

export type TNetInvestmentIncomeTaxTriggerYear = {
  year: number;
  threshold: number;
};

type TNetInvestmentIncomeTaxTriggers = {
  filingStatusCodes: Array<TFilingStatusCode>;
  years: Array<TNetInvestmentIncomeTaxTriggerYear>;
};

const single: TNetInvestmentIncomeTaxTriggers = {
  filingStatusCodes: ["s"],
  years: [
    { year: 2021, threshold: 200000 },
    { year: 2022, threshold: 200000 },
  ],
};

const marriedFilingSeperately: TNetInvestmentIncomeTaxTriggers = {
  filingStatusCodes: ["mfs"],
  years: [
    { year: 2021, threshold: 125000 },
    { year: 2022, threshold: 125000 },
  ],
};

const marriedFilingJointlyQualifiedWidow: TNetInvestmentIncomeTaxTriggers = {
  filingStatusCodes: ["mfj", "qw"],
  years: [
    { year: 2021, threshold: 250000 },
    { year: 2022, threshold: 250000 },
  ],
};

const headOfHousehold: TNetInvestmentIncomeTaxTriggers = {
  filingStatusCodes: ["hh"],
  years: [
    { year: 2021, threshold: 200000 },
    { year: 2022, threshold: 200000 },
  ],
};

const brackets = [
  single,
  marriedFilingSeperately,
  marriedFilingJointlyQualifiedWidow,
  headOfHousehold,
];

const getNetInvestmentIncomeTax = (
  filingStatusCode: TFilingStatusCode,
  filingYear: number = CURRENT_YEAR,
  magi: number = 0,
  investmentMagi: number = 0
) => {
  const filingStatusBrackets = brackets.find((b) =>
    b.filingStatusCodes.includes(filingStatusCode)
  )!;
  const { threshold } = filingStatusBrackets.years.find(
    (y) => y.year === filingYear
  )!;

  const amount = Math.min(investmentMagi, Math.max(0, magi - threshold));

  return {
    amount: amount * 0.038,
    explanation: "",
  };
};

export { getNetInvestmentIncomeTax };
