import { CURRENT_YEAR } from "./default-values";
import { TFilingStatusCode } from "./filing-status";

export type TBracketThreshold = {
  percentage: number;
  minTaxableIncome: number;
  maxTaxableIncome: number;
};

type TBracketYear = {
  year: number;
  brackets: Array<TBracketThreshold>;
};

type TLTCGAndQDBracket = {
  filingStatusCodes: Array<TFilingStatusCode>;
  years: Array<TBracketYear>;
};

const single: TLTCGAndQDBracket = {
  filingStatusCodes: ["s"],
  years: [
    {
      year: 2021,
      brackets: [
        {
          percentage: 0,
          minTaxableIncome: -Infinity,
          maxTaxableIncome: 40400,
        },
        {
          percentage: 15,
          minTaxableIncome: 40401,
          maxTaxableIncome: 445850,
        },
        {
          percentage: 20,
          minTaxableIncome: 445851,
          maxTaxableIncome: Infinity,
        },
      ],
    },
    {
      year: 2022,
      brackets: [
        {
          percentage: 0,
          minTaxableIncome: -Infinity,
          maxTaxableIncome: 41674,
        },
        {
          percentage: 15,
          minTaxableIncome: 41675,
          maxTaxableIncome: 459749,
        },
        {
          percentage: 20,
          minTaxableIncome: 459750,
          maxTaxableIncome: Infinity,
        },
      ],
    },
  ],
};

const marriedFilingSeperately: TLTCGAndQDBracket = {
  filingStatusCodes: ["mfs"],
  years: [
    {
      year: 2021,
      brackets: [
        {
          percentage: 0,
          minTaxableIncome: -Infinity,
          maxTaxableIncome: 40400,
        },
        {
          percentage: 15,
          minTaxableIncome: 40401,
          maxTaxableIncome: 250800,
        },
        {
          percentage: 20,
          minTaxableIncome: 250801,
          maxTaxableIncome: Infinity,
        },
      ],
    },
    {
      year: 2022,
      brackets: [
        {
          percentage: 0,
          minTaxableIncome: -Infinity,
          maxTaxableIncome: 41674,
        },
        {
          percentage: 15,
          minTaxableIncome: 41675,
          maxTaxableIncome: 258599,
        },
        {
          percentage: 20,
          minTaxableIncome: 258600,
          maxTaxableIncome: Infinity,
        },
      ],
    },
  ],
};

const marriedFilingJointly: TLTCGAndQDBracket = {
  filingStatusCodes: ["mfj"],
  years: [
    {
      year: 2021,
      brackets: [
        {
          percentage: 0,
          minTaxableIncome: -Infinity,
          maxTaxableIncome: 80800,
        },
        {
          percentage: 15,
          minTaxableIncome: 80801,
          maxTaxableIncome: 501600,
        },
        {
          percentage: 20,
          minTaxableIncome: 501601,
          maxTaxableIncome: Infinity,
        },
      ],
    },
    {
      year: 2022,
      brackets: [
        {
          percentage: 0,
          minTaxableIncome: -Infinity,
          maxTaxableIncome: 83349,
        },
        {
          percentage: 15,
          minTaxableIncome: 83350,
          maxTaxableIncome: 517199,
        },
        {
          percentage: 20,
          minTaxableIncome: 517200,
          maxTaxableIncome: Infinity,
        },
      ],
    },
  ],
};

const headOfHouseholdQualifiedWidow: TLTCGAndQDBracket = {
  filingStatusCodes: ["hh", "qw"],
  years: [
    {
      year: 2021,
      brackets: [
        {
          percentage: 0,
          minTaxableIncome: -Infinity,
          maxTaxableIncome: 54100,
        },
        {
          percentage: 15,
          minTaxableIncome: 54101,
          maxTaxableIncome: 473750,
        },
        {
          percentage: 20,
          minTaxableIncome: 473751,
          maxTaxableIncome: Infinity,
        },
      ],
    },
    {
      year: 2022,
      brackets: [
        {
          percentage: 0,
          minTaxableIncome: -Infinity,
          maxTaxableIncome: 55799,
        },
        {
          percentage: 15,
          minTaxableIncome: 55800,
          maxTaxableIncome: 488499,
        },
        {
          percentage: 20,
          minTaxableIncome: 488500,
          maxTaxableIncome: Infinity,
        },
      ],
    },
  ],
};

const brackets = [
  single,
  marriedFilingSeperately,
  marriedFilingJointly,
  headOfHouseholdQualifiedWidow,
];

const getCapitalGainsAndDividendsBracket = (
  filingStatusCode: TFilingStatusCode,
  filingYear: number = CURRENT_YEAR,
  taxableIncome: number
) => {
  const filingStatusBrackets = brackets.find((b) =>
    b.filingStatusCodes.includes(filingStatusCode)
  )!;
  const year = filingStatusBrackets.years.find((y) => y.year === filingYear)!;
  return year.brackets.find(
    (y) =>
      taxableIncome >= y.minTaxableIncome && taxableIncome <= y.maxTaxableIncome
  )!;
};

export { getCapitalGainsAndDividendsBracket };
