import FederalTaxEngine from "../types/federal-tax-engine";
import { localStorageGet } from "./local-storage";

const getSavedFederalTaxEngine = (targetFilingYear: number) => {
  const {
    filingYear,
    filingStatusCode,
    medicareWagesAndTips,
    selfEmployment,
    selfEmploymentIncome,
    spouseMedicareWagesAndTips,
    spouseSelfEmployment,
    spouseSelfEmploymentIncome,
    magi,
    longTermCapitalGains,
    qualifiedDividends,
    investmentMagi,
    federalTaxableIncome,
  } = localStorageGet(`federal-tax-engine-${targetFilingYear}`);

  const engine = new FederalTaxEngine(
    filingYear,
    filingStatusCode,
    medicareWagesAndTips,
    selfEmployment,
    selfEmploymentIncome,
    spouseMedicareWagesAndTips,
    spouseSelfEmployment,
    spouseSelfEmploymentIncome,
    magi,
    longTermCapitalGains,
    qualifiedDividends,
    investmentMagi,
    federalTaxableIncome
  );

  return engine;
};

export default getSavedFederalTaxEngine;
