import { Form, InputNumber, Select } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { CURRENT_YEAR } from "../../constants/default-values";
import getSavedFederalTaxEngine from "../../utils/get-saved-federal-tax-engine";
import FederalTaxEngineEditor from "../shared/federal-tax-engine-editor";
import CalculatorShell from "../shared/shell";
import {
  dollarFormatter,
  parser,
  percentageFormatter,
} from "../../constants/input-number-formats";
import FteComparison from "../shared/fte-comparison";
import CalculatorNotes from "../shared/notes";
import CalculatorResults from "../shared/results";
import FederalTaxEngine from "../../types/federal-tax-engine";
const { Option } = Select;

const PassiveIncomeGeneration = () => {
  const [form] = Form.useForm();
  const [preTaxBase, setPreTaxBase] = useState(0);
  const [postTaxBase, setPostTaxBase] = useState(0);
  const [postTaxEngine, setPostTaxEngine] = useState(new FederalTaxEngine());
  const filingYear = useWatch("filingYear", form) || CURRENT_YEAR;
  const activityLosses = useWatch("activityLosses", form);
  const investmentAmount = useWatch("investmentAmount", form);
  const financeRate = useWatch("financeRate", form);
  const investmentPortionReturned = useWatch("investmentPortionReturned", form);
  const nextPassiveIncome = useWatch("nextPassiveIncome", form);
  const additionalTax = Math.max(postTaxBase - preTaxBase, 0);
  const [modalVisible, setModalVisible] = useState(false);
  let results = {
    currentYearCashFlow:
      -1 * investmentAmount * ((100 - financeRate) / 100) || 0,
    expectedTaxFreeIncome: Math.min(activityLosses, nextPassiveIncome),
    expectedFutureTaxFlow: additionalTax ? -1 * additionalTax : 0,
    expectedFutureCashFlow: 0,
  };

  results.expectedFutureCashFlow =
    investmentAmount * ((100 - financeRate) / 100) -
    (investmentAmount - investmentPortionReturned);

  useEffect(() => {
    const additionalTax = Math.max(nextPassiveIncome - activityLosses, 0);
    let engine = getSavedFederalTaxEngine(filingYear);
    setPreTaxBase(engine.results.federalIncomeTaxBase);

    if (additionalTax > 0) {
      engine.federalTaxableIncome += additionalTax;
      engine.magi += additionalTax;
      engine.investmentMagi += additionalTax;
    }

    engine.calculate();
    setPostTaxBase(engine.results.federalIncomeTaxBase);
    setPostTaxEngine(engine);
  }, [modalVisible, nextPassiveIncome, activityLosses]);

  return (
    <CalculatorShell title="Passive Income Generation">
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          filingYear: CURRENT_YEAR,
          activityLosses: 0,
          investmentAmount: 0,
          financeRate: 0,
          investmentPortionReturned: 0,
          nextPassiveIncome: 0,
        }}
      >
        <Form.Item label="Filing year:" name="filingYear">
          <Select>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <FederalTaxEngineEditor
            type="link"
            filingYear={filingYear}
            onModalViewChange={(visible) => setModalVisible(visible)}
          />
        </Form.Item>
        <Form.Item
          label="Amount of unallowed passive activity losses reported on Part VII of Form 8582:"
          name="activityLosses"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label="Passive income generation investment amount:"
          name="investmentAmount"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label="Percentage of qualifying assets that will be financed (if applicable):"
          name="financeRate"
        >
          <InputNumber
            min={0}
            max={100}
            step={0.25}
            formatter={percentageFormatter}
          />
        </Form.Item>
        <Form.Item
          label="Total expected portion of original investment returned:"
          name="investmentPortionReturned"
        >
          <InputNumber
            min={0}
            max={investmentAmount}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label="Total expected next passive income earned for life of investment:"
          name="nextPassiveIncome"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item label="Additional tax on passive income exceeding passive losses, if applicable:">
          <InputNumber
            disabled
            value={parseFloat((additionalTax || 0).toFixed(2))}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        results={[
          {
            title: "Current Year Cash Flow",
            currencyResult: results.currentYearCashFlow,
          },
          {
            title: "Expected Total Tax Free Income",
            currencyResult: results.expectedTaxFreeIncome,
          },
          {
            title: "Expected Future Tax Flow",
            currencyResult: results.expectedFutureTaxFlow,
          },
          {
            title: "Expected Future Cash Flow",
            currencyResult: results.expectedFutureCashFlow,
          },
          {
            title: "Expected Net Tax Flow",
            boldTitle: true,
            currencyResult:
              results.currentYearCashFlow +
              results.expectedTaxFreeIncome +
              results.expectedFutureTaxFlow +
              results.expectedFutureCashFlow,
          },
        ]}
      >
        <CalculatorNotes
          notes={[
            "Does not factor in future future note payments (if applicable). Does not factor in additional passive activity losses",
          ]}
        />
        {additionalTax > 0 ? (
          <FteComparison filingYear={filingYear} results={postTaxEngine} />
        ) : null}
      </CalculatorResults>
    </CalculatorShell>
  );
};

export default PassiveIncomeGeneration;
