import { Badge, Card } from "antd";
import { RibbonProps } from "antd/lib/badge/Ribbon";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { SECONDARY_COLOR } from "../../../constants/colors";
const { Ribbon } = Badge;

const StyledRibbon = styled(Ribbon)`
  margin-top: -15px;
`;

const SignatureCard: React.FC<
  PropsWithChildren<RibbonProps & { background?: string, marginTop?: number }>
> = ({
  children,
  color = SECONDARY_COLOR,
  placement = "start",
  background,
  marginTop = 25,
  ...props
}) => (
  <StyledRibbon color={color} placement={placement} style={{fontSize: 14}} {...props}>
    <StyledCard
      style={{
        backgroundColor: background || "inherit",
        marginTop: marginTop || "inherit",
      }}
    >
      {children}
    </StyledCard>
  </StyledRibbon>
);

export default SignatureCard;


const StyledCard = styled(Card)`
    div.ant-card-body {
      padding-bottom: 16px
    }
`