import { TFilingStatusCode } from "./filing-status";

type TBracketThreshold = {
  maxUnder50: number;
  max50AndOver: number;
  magi: number;
};

type TBracketYear = {
  year: number;
  brackets: Array<TBracketThreshold>;
};

type TRothIraContributionLimitBracket = {
  filingStatusCodes: Array<TFilingStatusCode>;
  years: Array<TBracketYear>;
};

const single: TRothIraContributionLimitBracket = {
  filingStatusCodes: ["s"],
  years: [
    {
      year: 2021,
      brackets: [
        { magi: 125000, maxUnder50: 6000, max50AndOver: 7000 },
        { magi: 126500, maxUnder50: 5400, max50AndOver: 6300 },
        { magi: 128000, maxUnder50: 4800, max50AndOver: 5600 },
        { magi: 129500, maxUnder50: 4200, max50AndOver: 4900 },
        { magi: 131000, maxUnder50: 3600, max50AndOver: 4200 },
        { magi: 132500, maxUnder50: 3000, max50AndOver: 3500 },
        { magi: 134000, maxUnder50: 2400, max50AndOver: 2800 },
        { magi: 135500, maxUnder50: 1800, max50AndOver: 2100 },
        { magi: 137000, maxUnder50: 1200, max50AndOver: 1400 },
        { magi: 138500, maxUnder50: 600, max50AndOver: 700 },
        { magi: 140000, maxUnder50: 0, max50AndOver: 0 },
      ],
    },
    {
      year: 2022,
      brackets: [
        { magi: 129000, maxUnder50: 6000, max50AndOver: 7000 },
        { magi: 130500, maxUnder50: 5400, max50AndOver: 6300 },
        { magi: 132000, maxUnder50: 4800, max50AndOver: 5600 },
        { magi: 133500, maxUnder50: 4200, max50AndOver: 4900 },
        { magi: 135000, maxUnder50: 3600, max50AndOver: 4200 },
        { magi: 136500, maxUnder50: 3000, max50AndOver: 3500 },
        { magi: 138000, maxUnder50: 2400, max50AndOver: 2800 },
        { magi: 139500, maxUnder50: 1800, max50AndOver: 2100 },
        { magi: 141000, maxUnder50: 1200, max50AndOver: 1400 },
        { magi: 142500, maxUnder50: 600, max50AndOver: 700 },
        { magi: 144000, maxUnder50: 0, max50AndOver: 0 },
      ],
    },
  ],
};

const marriedFilingJointly: TRothIraContributionLimitBracket = {
  filingStatusCodes: ["mfj"],
  years: [
    {
      year: 2021,
      brackets: [
        { magi: 198000, maxUnder50: 6000, max50AndOver: 7000 },
        { magi: 199000, maxUnder50: 5400, max50AndOver: 6300 },
        { magi: 200000, maxUnder50: 4800, max50AndOver: 5600 },
        { magi: 201000, maxUnder50: 4200, max50AndOver: 4900 },
        { magi: 202000, maxUnder50: 3600, max50AndOver: 4200 },
        { magi: 203000, maxUnder50: 3000, max50AndOver: 3500 },
        { magi: 204000, maxUnder50: 2400, max50AndOver: 2800 },
        { magi: 205000, maxUnder50: 1800, max50AndOver: 2100 },
        { magi: 206000, maxUnder50: 1200, max50AndOver: 1400 },
        { magi: 207000, maxUnder50: 600, max50AndOver: 700 },
        { magi: 208000, maxUnder50: 0, max50AndOver: 0 },
      ],
    },
    {
      year: 2022,
      brackets: [
        { magi: 204000, maxUnder50: 6000, max50AndOver: 7000 },
        { magi: 205000, maxUnder50: 5400, max50AndOver: 6300 },
        { magi: 206000, maxUnder50: 4800, max50AndOver: 5600 },
        { magi: 207000, maxUnder50: 4200, max50AndOver: 4900 },
        { magi: 208000, maxUnder50: 3600, max50AndOver: 4200 },
        { magi: 209000, maxUnder50: 3000, max50AndOver: 3500 },
        { magi: 210000, maxUnder50: 2400, max50AndOver: 2800 },
        { magi: 211000, maxUnder50: 1800, max50AndOver: 2100 },
        { magi: 212000, maxUnder50: 1200, max50AndOver: 1400 },
        { magi: 213000, maxUnder50: 600, max50AndOver: 700 },
        { magi: 214000, maxUnder50: 0, max50AndOver: 0 },
      ],
    },
  ],
};

const marriedFilingSeperately: TRothIraContributionLimitBracket = {
  filingStatusCodes: ["mfs"],
  years: [
    {
      year: 2021,
      brackets: [
        { magi: 0, maxUnder50: 6000, max50AndOver: 7000 },
        { magi: 1000, maxUnder50: 5400, max50AndOver: 6300 },
        { magi: 2000, maxUnder50: 4800, max50AndOver: 5600 },
        { magi: 3000, maxUnder50: 4200, max50AndOver: 4900 },
        { magi: 4000, maxUnder50: 3600, max50AndOver: 4200 },
        { magi: 5000, maxUnder50: 3000, max50AndOver: 3500 },
        { magi: 6000, maxUnder50: 2400, max50AndOver: 2800 },
        { magi: 7000, maxUnder50: 1800, max50AndOver: 2100 },
        { magi: 8000, maxUnder50: 1200, max50AndOver: 1400 },
        { magi: 9000, maxUnder50: 600, max50AndOver: 700 },
        { magi: 10000, maxUnder50: 0, max50AndOver: 0 },
      ],
    },
    {
      year: 2022,
      brackets: [
        { magi: 0, maxUnder50: 6000, max50AndOver: 7000 },
        { magi: 1000, maxUnder50: 5400, max50AndOver: 6300 },
        { magi: 2000, maxUnder50: 4800, max50AndOver: 5600 },
        { magi: 3000, maxUnder50: 4200, max50AndOver: 4900 },
        { magi: 4000, maxUnder50: 3600, max50AndOver: 4200 },
        { magi: 5000, maxUnder50: 3000, max50AndOver: 3500 },
        { magi: 6000, maxUnder50: 2400, max50AndOver: 2800 },
        { magi: 7000, maxUnder50: 1800, max50AndOver: 2100 },
        { magi: 8000, maxUnder50: 1200, max50AndOver: 1400 },
        { magi: 9000, maxUnder50: 600, max50AndOver: 700 },
        { magi: 10000, maxUnder50: 0, max50AndOver: 0 },
      ],
    },
  ],
};

const brackets = [single, marriedFilingJointly, marriedFilingSeperately];

export const calculateContributionLimit = (
  filingStatus: TFilingStatusCode,
  filingYear: number,
  magi: number,
  over50: boolean
) => {
  const bracketCategory = brackets.find(
    (b) =>
      b.filingStatusCodes.includes(filingStatus)
  );

  if (!bracketCategory) {
    console.error(`Roth contribution bracket not found for ${filingStatus}`);
    return;
  }

  const bracketYear = bracketCategory.years.find(y => y.year === filingYear)!;

  if (!bracketYear) {
    console.error(`Roth contribution bracket year not found for ${filingStatus}`);
    return;
  }


  const magiTiers = bracketYear.brackets.map(b => b.magi);

  var result = Math.abs(Math.max.apply(Math, magiTiers.filter((x) => {return x <= magi})));

  if (result === Infinity) {
    result = magiTiers[0];
  }

  const result2 = bracketYear.brackets.find(b => b.magi === result)!;
  return over50 ? result2.max50AndOver : result2.maxUnder50;
};