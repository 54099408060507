import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 30px;
  margin-top: -10px;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
`;

const Option = styled.div`
  display: inline;
  cursor: pointer;
  color: #d3d3d3;

  small {
    transition: all 300ms;
  }

  &:hover > small {
    font-size: 13px;
    color: white;
  }
`;

const openTab = (route?: string) => {
  window.open(`https://www.teachingtaxflow.com/${route}`, "_blank");
};

const Footer = () => {
  const { pathname } = useLocation();

  return (
    <Container style={{ flexDirection: "column" }}>
      <div>
        <Option onClick={() => openTab("")}>
          <small>© {new Date().getFullYear()} Teaching Tax Flow LLC</small>
        </Option>
        <small style={{ marginLeft: 8, marginRight: 8 }}>|</small>
        <Option onClick={() => openTab("privacy-policy")}>
          <small>Privacy Policy</small>
        </Option>
        <small style={{ marginLeft: 8, marginRight: 8 }}>|</small>
        <Option onClick={() => openTab("terms-of-use")}>
          <small>Terms of Use</small>
        </Option>
      </div>
      {pathname.includes("restricted") || pathname === '/' ? null : (
        <div
          style={{
            width: "calc(100% + 24px)",
            background: "white",
            marginTop: 12,
            marginBottom: -12,
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: 600,
              whiteSpace: "break-spaces",
              textAlign: "center",
              width: "75%",
              paddingTop: 12,
              paddingBottom: 12,
              fontSize: 8,
            }}
          >
            Calculations do not factor in changes to Qualified Business Income
            or Alternative Minimum Tax. These calculations are for estimation
            and informational purposes only, and are not intended to provide,
            and should not be relied on for, tax, legal or accounting advice.
          </div>
        </div>
      )}
    </Container>
  );
};

export default Footer;
