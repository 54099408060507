import { Layout } from "antd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FteDataYearProvider from "./contexts/federal-tax-engine-data/federal-tax-engine-data";
import Menu from "./pages/menu";
import { calculators } from "./calculator-metadata";
import useMediaQuery from "use-media-antd-query";
import VideoViewer from "./pages/video/VideoViewer";
import "antd/dist/antd.css";
import "antd-button-color/dist/css/style.css";
import "./index.css";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { PropsWithChildren } from "react";
import RestrictedArea from "./pages/restricted-area";
import Footer from "./components/footer";
const { Content } = Layout;

function App() {
  const colSize = useMediaQuery();

  return (
    <BrowserRouter>
      <Layout style={{ minHeight: "100vh" }}>
        <Auth0Provider
          domain="ttf-calculators.us.auth0.com"
          clientId="BglfW8Bahk1yzlWGuzupXPk0QfMLKrOZ"
          redirectUri={window.location.origin + "/home"}
        >
          <Content
            style={{
              padding: ["xs", "sm"].includes(colSize) ? 12.5 : 25,
              paddingBottom: 0,
            }}
          >
            <FteDataYearProvider>
              <Routes>
                {calculators
                  .filter((c) => !c.disabled)
                  .map(({ path, component }, index) => (
                    <Route key={index} path={path} element={component} />
                  ))}
                <Route path="tutorials/:code" element={<VideoViewer />} />
                <Route path="restricted" element={<RestrictedArea />} />
                <Route path="*" element={<Menu />} />
              </Routes>
            </FteDataYearProvider>
          </Content>
          <Footer />
        </Auth0Provider>
      </Layout>
    </BrowserRouter>
  );
}
export default App;

const ProtectedRoute: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const { user } = useAuth0();

  return user ? children : <RestrictedArea />;
};
