import { useAuth0 } from "@auth0/auth0-react";
import { Divider, Typography } from "antd";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useMediaQuery from "use-media-antd-query";
import Authentication from "../auth";
import LowerOnly from "../lower-only";

const LogoContainer = styled.div<{ marginBottom: number }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const SmallLogo = styled.img`
  height: 50px;
  width: auto;
`;

const LargeLogo = styled.img`
  height: auto;
  width: 150px;
`;

const HomeLink = () => (
  <Link to="/">
    <div style={{ display: "flex", alignItems: "center", marginBottom: 12 }}>
      <BsArrowLeft />
      <span style={{ marginLeft: 3 }}>Calculators</span>
    </div>
  </Link>
);

const SmartTitle: React.FC<{
  title: string;
  titleSmall?: string;
  hideHomeLink?: boolean;
  icon?: JSX.Element;
}> = ({ title, titleSmall, hideHomeLink, icon }) => {
  const { user } = useAuth0();
  const colSize = useMediaQuery();

  return (
    <>
      <LowerOnly>{hideHomeLink ? null : <HomeLink />}</LowerOnly>
      <LogoContainer marginBottom={["xs", "sm"].includes(colSize) ? 12 : 0}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <Typography.Title level={4}>
              {["xs", "sm"].includes(colSize) ? titleSmall || title : title}
              {icon ? <span style={{ marginLeft: 8 }}>{icon}</span> : null}
            </Typography.Title>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {["xs", "sm"].includes(colSize) ? (
            <SmallLogo src="./teaching-tax-flow-small.png" alt="" />
          ) : (
            <LargeLogo src="./teaching-tax-flow-large.png" alt="" />
          )}
          {user ? <Authentication /> : null}
        </div>
      </LogoContainer>
      <Divider />
    </>
  );
};

export default SmartTitle;
