import { TFilingBracketTier, TFilingStatusTaxBracket } from "./types";

const tiers2021: Array<TFilingBracketTier> = [
  {
    rate: 0.1,
    min: 0,
    max: 9950,
    fixedTaxAmount: 0,
  },
  {
    rate: 0.12,
    min: 9951,
    max: 40525,
    fixedTaxAmount: 995,
  },
  {
    rate: 0.22,
    min: 40526,
    max: 86375,
    fixedTaxAmount: 4664,
  },
  {
    rate: 0.24,
    min: 86376,
    max: 164925,
    fixedTaxAmount: 14751,
  },
  {
    rate: 0.32,
    min: 164926,
    max: 209425,
    fixedTaxAmount: 33603,
  },
  {
    rate: 0.35,
    min: 209426,
    max: 523600,
    fixedTaxAmount: 47843,
  },
  {
    rate: 0.37,
    min: 523601,
    max: Infinity,
    fixedTaxAmount: 157804.25,
  },
];

const tiers2022: Array<TFilingBracketTier> = [
  {
    rate: 0.1,
    min: 0,
    max: 10275,
    fixedTaxAmount: 0,
  },
  {
    rate: 0.12,
    min: 10276,
    max: 41775,
    fixedTaxAmount: 1027.5,
  },
  {
    rate: 0.22,
    min: 41776,
    max: 89075,
    fixedTaxAmount: 4807.5,
  },
  {
    rate: 0.24,
    min: 89076,
    max: 170050,
    fixedTaxAmount: 15213.5,
  },
  {
    rate: 0.32,
    min: 170051,
    max: 215950,
    fixedTaxAmount: 34647.5,
  },
  {
    rate: 0.35,
    min: 215951,
    max: 539900,
    fixedTaxAmount: 49335.5,
  },
  {
    rate: 0.37,
    min: 539901,
    max: Infinity,
    fixedTaxAmount: 162718,
  },
];

const single: TFilingStatusTaxBracket = {
  filingStatusCodes: ["s"],
  years: [
    {
      year: 2021,
      tiers: tiers2021,
      standardDeduction: 12550,
    },
    {
      year: 2022,
      tiers: tiers2022,
      standardDeduction: 12950,
    },
  ],
};

export default single;
