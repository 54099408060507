import { LinkOutlined } from "@ant-design/icons";
import { message as antdMessage } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyButton: React.FC<{ content: string; message: string }> = ({
  content,
  message,
}) => {
  return (
    <CopyToClipboard
      text={content}
      onCopy={() => {
        antdMessage.success('Link copied!');
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: "#1890ff",
          cursor: "pointer",
        }}
      >
        <LinkOutlined />
        <span style={{ marginLeft: 3 }}>Link</span>
      </div>
    </CopyToClipboard>
  );
};

export default CopyButton;
