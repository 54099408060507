import { Form, InputNumber } from "antd";
import { useWatch } from "antd/lib/form/Form";
import {
  dollarFormatter,
  parser,
  percentageFormatter,
} from "../../constants/input-number-formats";
import { currency } from "../../utils/currency";
import CalculatorNotes from "../shared/notes";
import CalculatorResults from "../shared/results";
import CalculatorShell from "../shared/shell";

const Section1202QSBS = () => {
  const [form] = Form.useForm();
  const section1202Amount = useWatch("section1202Amount", form);
  const expectedAnnualRoi = useWatch("expectedAnnualRoi", form);
  const yearsUntilQualifiedSale = useWatch("yearsUntilQualifiedSale", form);

  return (
    <CalculatorShell title="Section 1202 QSBS">
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          section1202Amount: 0,
          yearsUntilQualifiedSale: 5,
          expectedAnnualRoi: 2.0,
        }}
      >
        <Form.Item
          label="Investment in Section 1202 qualified small business stock:"
          name="section1202Amount"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label="Years until qualified sale:"
          name="yearsUntilQualifiedSale"
        >
          <InputNumber min={5} max={50} step={1} />
        </Form.Item>
        <Form.Item
          label="Expected annual rate of return on investment:"
          name="expectedAnnualRoi"
        >
          <InputNumber
            min={2}
            max={25}
            step={0.25}
            formatter={percentageFormatter}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        showDivider
        results={[
          {
            title: "Current Year Cash Flow",
            content:
              section1202Amount > 0
                ? currency.format(-1 * section1202Amount)
                : currency.format(0),
          },
          {
            title: "Current Year Tax Flow",
            content: currency.format(0),
          },
          {
            title: "Expected Future Years Cash Flow",
            content: currency.format(
              section1202Amount *
                (1 + expectedAnnualRoi / 100) ** yearsUntilQualifiedSale
            ),
          },
          {
            title: "Expected Net Tax Flow",
            content: currency.format(
              section1202Amount *
                (1 + expectedAnnualRoi / 100) ** yearsUntilQualifiedSale +
                -1 * section1202Amount
            ),
            boldTitle: true,
          },
        ]}
      >
        {" "}
        <CalculatorNotes
          notes={[
            "Potential future years tax flow represents tax free growth and all capital gains excludable.",
            "Does not factor in dividends paid during holding period.",
          ]}
        />
      </CalculatorResults>
    </CalculatorShell>
  );
};

export default Section1202QSBS;
