import { TFilingBracketTier, TFilingStatusTaxBracket } from "./types";

const tiers2021: Array<TFilingBracketTier> = [
  {
    rate: 0.1,
    min: 0,
    max: 19900,
    fixedTaxAmount: 0,
  },
  {
    rate: 0.12,
    min: 19901,
    max: 81050,
    fixedTaxAmount: 1990,
  },
  {
    rate: 0.22,
    min: 81051,
    max: 172750,
    fixedTaxAmount: 9328,
  },
  {
    rate: 0.24,
    min: 172751,
    max: 329850,
    fixedTaxAmount: 29502,
  },
  {
    rate: 0.32,
    min: 329851,
    max: 418850,
    fixedTaxAmount: 67206,
  },
  {
    rate: 0.35,
    min: 418851,
    max: 628300,
    fixedTaxAmount: 95686,
  },
  {
    rate: 0.37,
    min: 628301,
    max: Infinity,
    fixedTaxAmount: 168993.5,
  },
];

const tiers2022: Array<TFilingBracketTier> = [
  {
    rate: 0.1,
    min: 0,
    max: 20550,
    fixedTaxAmount: 0,
  },
  {
    rate: 0.12,
    min: 20551,
    max: 83550,
    fixedTaxAmount: 2055,
  },
  {
    rate: 0.22,
    min: 83551,
    max: 178150,
    fixedTaxAmount: 9615,
  },
  {
    rate: 0.24,
    min: 178151,
    max: 340100,
    fixedTaxAmount: 30427,
  },
  {
    rate: 0.32,
    min: 340101,
    max: 431900,
    fixedTaxAmount: 69295,
  },
  {
    rate: 0.35,
    min: 431901,
    max: 647850,
    fixedTaxAmount: 98671,
  },
  {
    rate: 0.37,
    min: 647851,
    max: Infinity,
    fixedTaxAmount: 174253.5,
  },
];

const marriedFilingJointly: TFilingStatusTaxBracket = {
  filingStatusCodes: ["mfj"],
  years: [
    {
      year: 2021,
      tiers: tiers2021,
      standardDeduction: 25100,
    },
    {
      year: 2022,
      tiers: tiers2022,
      standardDeduction: 25900,
    },
  ],
};

export default marriedFilingJointly;
