import { Avatar, Button, Divider, Popover, Spin } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { UserOutlined } from "@ant-design/icons";

const StyledAvatar = styled(Avatar)`
  margin-left: 24px;
  cursor: pointer;
  borderradius: 100%;
  marginbottom: 12px;
  border: 1px solid #d3d3d3;

  &:hover {
    box-shadow: 0px 0px 3px 5px rgb(102 45 145 / 30%);
    transform: scale(1.1);
  }
`;

const Authentication = () => {
  const { user, logout, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  // if (!user) {
  //   navigate("/");
  // }

  return (
    <div className="container" style={{ display: "none" }}>
      <Popover
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              src={user?.picture}
              icon={<UserOutlined />}
              size={60}
              style={{
                borderRadius: "100%",
                marginBottom: 12,
              }}
              alt="user profile"
            />
            <span style={{ fontSize: 16 }}>
              {user?.given_name} {user?.family_name}{" "}
            </span>
            <span style={{ fontSize: 12 }}>{user?.email}</span>
            <Divider style={{ margin: "12px 0px" }} />
            <Button
              type="default"
              onClick={() => (user ? logout() : loginWithRedirect())}
            >
              Sign {user ? "out" : "in"}
            </Button>
          </div>
        }
        placement="bottomRight"
        trigger="click"
      >
        <StyledAvatar src={user?.picture} icon={<UserOutlined />} size={40} />
      </Popover>
    </div>
  );
};

export default Authentication;
