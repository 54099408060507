import { Card, Col, Collapse, List, Row } from "antd";
import { calculators, CalculatorStatusColors } from "../../calculator-metadata";
import CopyButton from "../../components/copy-button";
import Authentication from "../../components/auth";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { FcCalculator, FcCalendar} from 'react-icons/fc'

const Pill = styled.span`
  min-width: 50px;
  display: block;
  font-size: 10px;
  color: white;
  padding: 1px 6px;
  border-radius: 16px;
  margin-left: 6px;
  user-select: none;
  box-shadow: rgb(38, 57, 77) 0px 2px 4px 0px;
`;

const Index = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (process.env.REACT_APP_ENV === "production") {
  //     navigate("restricted");
  //   }
  // }, []);

  return (
    <Card>
      <Row
        gutter={12}
        align="middle"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col xs={24} md={18} xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 24,
            }}
          >
            <img
              style={{ height: 45, width: 150 }}
              src="./teaching-tax-flow-large.png"
              alt=""
            />
            <Authentication />
          </div>

          <List
            dataSource={calculators}
            bordered
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Pill
                    style={{
                      backgroundColor: CalculatorStatusColors.get(item.status),
                    }}
                  >
                    {item.status}
                  </Pill>,
                  <CopyButton
                    content={window.location + item.path}
                    message={`"${item.name}"`}
                  />,
                ]}
              >
                <List.Item.Meta
                  title={
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <a href={item.path}>{item.name}</a>
                      </div>
                    </div>
                  }
                  // description={
                  //   <div>
                  //     <FcCalendar size={20}/>
                  //     <FcCalculator size={20}/>
                  //   </div>
                  // }
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Index;
