export type TFilingStatusLabel =
  | "Single"
  | "Married Filing Jointly"
  | "Married Filing Seperately"
  | "Head of Household"
  | "Qualified Widow";

export type TFilingStatusCode = "s" | "mfj" | "mfs" | "hh" | "qw";

export type TFilingStatus = {
  label: TFilingStatusLabel;
  code: TFilingStatusCode;
  jointly: boolean;
};

const single: TFilingStatus = {
  label: "Single",
  code: "s",
  jointly: false,
};

const marriedFilingJointly: TFilingStatus = {
  label: "Married Filing Jointly",
  code: "mfj",
  jointly: true,
};

const marriedFilingSeperately: TFilingStatus = {
  label: "Married Filing Seperately",
  code: "mfs",
  jointly: false,
};

const headOfHousehold: TFilingStatus = {
  label: "Head of Household",
  code: "hh",
  jointly: false,
};

const qualifiedWidow: TFilingStatus = {
  label: "Qualified Widow",
  code: "qw",
  jointly: false,
};

export const statuses = [
  single,
  marriedFilingJointly,
  marriedFilingSeperately,
  headOfHousehold,
  qualifiedWidow,
];
