import styled from "styled-components";
import SignatureCard from "../signature-card";

type Props = {
  notes: string | Array<string>;
};

const CalculatorNotes: React.FC<Props> = ({ notes }) => {
  const single =
    typeof notes === "string" || (Array.isArray(notes) && notes.length === 1);

  return (
    <SignatureCard text={`Note${single ? "" : "s"}`} background="#ffffe0">
      <NotesContainer>
        {single ? (
          <SingleNote>{notes}</SingleNote>
        ) : (
          <NoteList>
            {notes.map((note, index) => (
              <Note key={index}>
                <span>{note}</span>
              </Note>
            ))}
          </NoteList>
        )}
      </NotesContainer>
    </SignatureCard>
  );
};

export default CalculatorNotes;

const SingleNote = styled.span`
  margin-top: 12px;
  display: block;
`;

const NotesContainer = styled.div`
  margin-top: -12px;
  font-size: 12px;
  font-style: italic;

  b {
    display: block;
    font-size: 14px;
  }
`;

const NoteList = styled.ul`
  margin-top: 4px;

  li:not(:last-of-type) {
    margin-bottom: 6px;
  }
`;

const Note = styled.li`
  margin-left: -20px;
  white-space: normal;
  float: left;
  width: 100%;
  height: auto;
  word-wrap: break-word;

  span {
    position: relative;
    left: -6px;
  }
`;
