import { Form, InputNumber, Select } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { CURRENT_YEAR } from "../../constants/default-values";
import { dollarFormatter, parser } from "../../constants/input-number-formats";
import getSavedFederalTaxEngine from "../../utils/get-saved-federal-tax-engine";
import FederalTaxEngineEditor from "../shared/federal-tax-engine-editor";
import CalculatorNotes from "../shared/notes";
import CalculatorResults from "../shared/results";
import CalculatorShell from "../shared/shell";

const AppreciatedNonCashAssetsToCharity = () => {
  const [form] = Form.useForm();
  const [preTax, setPreTax] = useState(0);
  const [deductiblePortion, setDeductiblePortion] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const filingYear = useWatch("filingYear", form);
  const marketValue = useWatch("marketValue", form);
  const [postTax, setPostTax] = useState(0);

  useEffect(() => {
    let engine = getSavedFederalTaxEngine(filingYear);
    setPreTax(engine.results.estimatedTotalTaxes);
    const deductiblePortion = Math.min(marketValue, engine.magi * 0.5);
    engine.federalTaxableIncome -= deductiblePortion;
    engine.calculate();
    setDeductiblePortion(deductiblePortion);
    setPostTax(engine.results.estimatedTotalTaxes);
  }, [filingYear, modalVisible, marketValue]);

  return (
    <CalculatorShell title="Donate Appreciated Non-Cash Assets To Charity">
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          filingYear: CURRENT_YEAR,
          filingStatusCode: "s",
          adjustedGrossIncome: 0,
          itemizeDeductions: false,
          marketValue: 0,
        }}
      >
        <Form.Item label="Filing year:" name="filingYear">
          <Select>
            {[2021, 2022].map((year, index) => (
              <Select.Option key={index} value={year}>
                {year}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <FederalTaxEngineEditor
            type="link"
            filingYear={filingYear}
            onModalViewChange={(visible) => setModalVisible(visible)}
          />
        </Form.Item>
        <Form.Item label={`${filingYear} base federal income tax:`}>
          <InputNumber
            disabled
            value={preTax}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label="Fair market value of donated appreciated property:"
          name="marketValue"
        >
          <InputNumber min={0} formatter={dollarFormatter} />
        </Form.Item>
        <Form.Item label="Deductible portion of donated appreciated property:">
          <InputNumber
            disabled
            formatter={dollarFormatter}
            value={deductiblePortion}
          />
        </Form.Item>
        <Form.Item label="Estimated federal income tax after donation:">
          <InputNumber disabled formatter={dollarFormatter} value={postTax} />
        </Form.Item>
      </Form>
      <CalculatorResults
        results={[
          {
            title: "Cash Year Cash Flow",
            currencyResult: 0,
          },
          {
            title: "Current Year Tax Flow",
            currencyResult: preTax - postTax,
          },
          {
            title: "Expected Net Tax Flow",
            boldTitle: true,
            currencyResult: preTax - postTax,
          },
        ]}
      >
        <CalculatorNotes
          notes={[
            "Calculator assumes taxpayer itemizes deductions and property is eligible for 50% limit",
          ]}
        />
      </CalculatorResults>
    </CalculatorShell>
  );
};

export default AppreciatedNonCashAssetsToCharity;
