type Props = {
  primaryColor?: string;
  secondaryColor?: string;
};

const DynamicLogo: React.FC<Props> = ({ primaryColor, secondaryColor }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 346 292"
      enable-background="new 0 0 346 292"
      xmlSpace="preserve"
    >
      <path
        fill={primaryColor || "#662D91"}
        opacity="1.000000"
        stroke="none"
        d="
				M100 292C77 291 76 290 76 290 68 289 61 284 53 281 41 274 32 265 25 254 16 242 12 227 10 212 7 196 10 180 16 165 23 147 35 132 52 122 67 113 82 108 100 108 160 108 220 108 280 108 282 108 283 108 286 108 285 115 285 121 284 127 281 139 275 150 266 158 255 168 242 174 227 174 186 174 146 174 106 174 90 174 78 185 77 200 76 208 81 215 87 220 102 232 116 226 125 212 130 204 130 195 130 186 152 186 174 186 196 186 196 199 198 211 194 222 190 233 186 244 180 253 175 262 168 269 160 275 152 281 143 284 135 289 133 290 130 290 128 292 111 292 95 292 77 292Z
z"
      />
      <path
        fill={secondaryColor || "#92278F"}
        opacity="1.000000"
        stroke="none"
        d="
				M338 1C337 9 338 17 336 25 333 32 329 39 325 45 318 54 309 60 299 64 294 66 288 67 282 67 255 67 228 67 202 67 200 67 198 67 195 67 195 77 195 87 195 97 173 97 151 97 129 97 129 87 129 77 129 67 94 67 59 67 22 67 23 60 24 53 26 47 29 30 39 18 54 9 59 6 64 5 70 3 70 3 71 2 71 1 160 1 248 1 338 1z"
      />
    </svg>
  );
};

export default DynamicLogo;
