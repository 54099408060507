import { Col, Form, InputNumber, notification, Radio, Row, Select } from "antd";
import { statuses } from "../../constants/filing-status";
import FederalTaxEngine, {
  FederalTaxEngineParameterField,
} from "../../types/federal-tax-engine";
import { useEffect, useState } from "react";
import { useWatch } from "antd/lib/form/Form";
import { CURRENT_YEAR } from "../../constants/default-values";
import { localStorageGet } from "../../utils/local-storage";
import Button from "antd-button-color";
import { dollarFormatter, parser } from "../../constants/input-number-formats";
import CalculatorShell from "../shared/shell";
import CalculatorResults from "../shared/results";
import { useFteContext } from "../../contexts/federal-tax-engine-data/federal-tax-engine-data";
import {
  TFederalTaxEngineCalculatorForm,
  TFederalTaxEngineProps,
  TFederalTaxEngineValueChange,
} from "./types";
import VideoLabel from "../../components/video-label/VideoLabel";
const { Option } = Select;

const FederalTaxEngineCalculator: React.FC<TFederalTaxEngineProps> = ({
  modal,
  year,
  onDismiss,
}) => {
  const { saveResults } = useFteContext();
  const [form] = Form.useForm<TFederalTaxEngineCalculatorForm>();
  const filingYear = useWatch("filingYear", form);
  const filingStatusCode = useWatch("filingStatusCode", form);
  const selfEmployment = useWatch("selfEmployment", form);
  const spouseSelfEmployment = useWatch("spouseSelfEmployment", form);
  const [engineResults, setEngineResults] = useState<{
    federalIncomeTaxRate: number;
    federalIncomeTaxBase: number;
    federalIncomeTaxExplanation: string;
    capitalGainsTaxRate: number;
    additionalMedicareTax: number;
    additionalMedicareTaxExplanation: string;
    netInvestmentIncomeTax: number;
    netInvestmentIncomeTaxExplanation: string;
    capitalGainsAndDividendsTax: number;
    capitalGainsAndDividendsTaxExplanation: string;
    selfEmployedFicaTax: {
      self: number;
      spouse: number;
    };
    selfEmployedFicaTaxExplanation: {
      self: string;
      spouse: string;
    };
    selfEmployedMedicareTax: {
      self: number;
      spouse: number;
    };
    selfEmployedMedicareTaxExplanation: {
      self: string;
      spouse: string;
    };
    estimatedTotalTaxes: number;
    standardDeduction: number;
  }>({
    federalIncomeTaxRate: 0,
    federalIncomeTaxBase: 0,
    federalIncomeTaxExplanation: "",
    capitalGainsTaxRate: 0,
    additionalMedicareTax: 0,
    additionalMedicareTaxExplanation: "",
    netInvestmentIncomeTax: 0,
    netInvestmentIncomeTaxExplanation: "",
    capitalGainsAndDividendsTax: 0,
    capitalGainsAndDividendsTaxExplanation: "",
    selfEmployedFicaTax: {
      self: 0,
      spouse: 0,
    },
    selfEmployedFicaTaxExplanation: {
      self: "",
      spouse: "",
    },
    selfEmployedMedicareTax: {
      self: 0,
      spouse: 0,
    },
    selfEmployedMedicareTaxExplanation: {
      self: "",
      spouse: "",
    },
    estimatedTotalTaxes: 0,
    standardDeduction: 0,
  });

  const updateEngine = (field: FederalTaxEngineParameterField, value: any) => {
    const {
      filingStatusCode,
      medicareWagesAndTips,
      selfEmployment,
      selfEmploymentIncome,
      spouseMedicareWagesAndTips,
      spouseSelfEmployment,
      spouseSelfEmploymentIncome,
      magi,
      longTermCapitalGains,
      qualifiedDividends,
      investmentMagi,
      federalTaxableIncome,
    } = form.getFieldsValue();
    const engine = new FederalTaxEngine(
      filingYear,
      filingStatusCode,
      medicareWagesAndTips,
      selfEmployment,
      selfEmploymentIncome,
      spouseMedicareWagesAndTips,
      spouseSelfEmployment,
      spouseSelfEmploymentIncome,
      magi,
      longTermCapitalGains,
      qualifiedDividends,
      investmentMagi,
      federalTaxableIncome
    );

    engine.updateField(
      field,
      typeof value === "number" ? Math.max(value, 0) : value
    );

    engine.calculate();

    if (!engine.selfEmployment) {
      form.setFieldsValue({ selfEmploymentIncome: 0 });
    }

    if (!engine.spouseSelfEmployment) {
      form.setFieldsValue({ spouseSelfEmploymentIncome: 0 });
    }

    const { results } = engine;
    setEngineResults({ ...results });
  };

  useEffect(() => {
    const taxEngineResults = localStorageGet(
      `federal-tax-engine-${year || filingYear}`
    );
    if (taxEngineResults) {
      setEngineResults(taxEngineResults);
      Object.keys(taxEngineResults).forEach((key) => {
        if (key !== "results") {
          //@ts-ignore
          form.setFieldsValue({ ...taxEngineResults });
        }
      });
    }
  }, [year, filingYear]);

  const save = () => {
    try {
      const formData = form.getFieldsValue();
      saveResults({ ...formData, ...engineResults });
      notification.success({
        message: `${filingYear} tax estimates saved!`,
        placement: "top",
        duration: 2.5,
      });
    } catch (error) {
      notification.error({
        message: `${filingYear} tax estimate could not be saved. Please try again later`,
        placement: "top",
        duration: 5,
      });
    }
  };

  return (
    <CalculatorShell
      title={`${modal ? filingYear : ""} Federal Tax Engine`}
      hideHomeLink={modal}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          filingYear: year || CURRENT_YEAR,
          filingStatusCode: "s",
          selfEmployment: false,
          selfEmploymentIncome: 0,
          medicareWagesAndTips: 0,
          longTermCapitalGains: 0,
          qualifiedDividends: 0,
          investmentMagi: 0,
          federalTaxableIncome: 0,
          magi: 0,
          spouseMedicareWagesAndTips: 0,
          spouseSelfEmployment: false,
          spouseSelfEmploymentIncome: 0,
        }}
        onValuesChange={(value: TFederalTaxEngineValueChange) => {
          const field = Object.keys(value)[0] as FederalTaxEngineParameterField;
          updateEngine(field, value[field]);
        }}
      >
        <Form.Item
          label="Filing year:"
          name="filingYear"
          style={{ display: modal ? "none" : "inherit" }}
        >
          <Select disabled={modal}>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={<VideoLabel label="Filing status" videoId="1" />}
          name="filingStatusCode"
        >
          <Select>
            {statuses.map((d, index) => (
              <Option key={index} value={d.code}>
                {d.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={<VideoLabel label="Federal taxable income" videoId="3" />}
          name="federalTaxableIncome"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label={<VideoLabel label="Adjusted gross income (AGI)" videoId="2" />}
          name="magi"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label={
            <VideoLabel
              label="Medicare wages and tips from all form W-2s, box 5"
              videoId="4"
            />
          }
          name="medicareWagesAndTips"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label="Do you have self-employment income?"
          name="selfEmployment"
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          style={{ display: selfEmployment ? "inherit" : "none" }}
          label={
            <VideoLabel label="Schedule SE (Form 1040), line 3" videoId="5" />
          }
          name="selfEmploymentIncome"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          style={{
            display: filingStatusCode === "mfj" ? "inherit" : "none",
          }}
          label="Spouse's Medicare wages and tips from all form W-2s, box 5:"
          name="spouseMedicareWagesAndTips"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          style={{
            display: filingStatusCode === "mfj" ? "inherit" : "none",
          }}
          label="Does your spouse have self-employment income?"
          name="spouseSelfEmployment"
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          style={{
            display:
              filingStatusCode === "mfj" && spouseSelfEmployment
                ? "inherit"
                : "none",
          }}
          label="Spouse's Schedule SE (Form 1040), line 3:"
          name="spouseSelfEmploymentIncome"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label={
            <VideoLabel
              label="Long-term capital gains (LTCG) income"
              videoId="6"
            />
          }
          name="longTermCapitalGains"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label={
            <VideoLabel label="Qualified dividends (QD) income" videoId="7" />
          }
          name="qualifiedDividends"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label={
            <VideoLabel label="Investment income portion of AGI" videoId="8" />
          }
          name="investmentMagi"
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
      </Form>
      <CalculatorResults
        colSize={modal ? 24 : undefined}
        results={[
          {
            title: "Federal Taxes",
            currencyResult: engineResults.federalIncomeTaxBase,
            explanationText:
              engineResults.federalIncomeTaxBase > 0
                ? engineResults.federalIncomeTaxExplanation
                : "",
          },
          {
            title: "Additional Medicare Tax",
            currencyResult: engineResults.additionalMedicareTax,
            explanationText:
              engineResults.additionalMedicareTax > 0
                ? engineResults.additionalMedicareTaxExplanation
                : "",
            hide: engineResults.additionalMedicareTax === 0,
          },
          {
            title: "Net Investment Income Tax",
            currencyResult: engineResults.netInvestmentIncomeTax,
            explanationText:
              engineResults.netInvestmentIncomeTax > 0
                ? engineResults.netInvestmentIncomeTaxExplanation
                : "",
            hide: engineResults.netInvestmentIncomeTax === 0,
          },
          {
            title: "Self-Emplyoment Tax FICA",
            currencyResult: engineResults.selfEmployedFicaTax.self,
            explanationText:
              engineResults.selfEmployedFicaTax.self > 0
                ? engineResults.selfEmployedFicaTaxExplanation.self
                : "",
            hide:
              !selfEmployment || engineResults.selfEmployedFicaTax.self === 0,
          },
          {
            title: "Self-Emplyoment Tax Medicare",
            currencyResult: engineResults.selfEmployedMedicareTax.self,
            explanationText:
              engineResults.selfEmployedMedicareTax.self > 0
                ? engineResults.selfEmployedMedicareTaxExplanation.self
                : "",
            hide:
              !selfEmployment ||
              engineResults.selfEmployedMedicareTax.self === 0,
          },
          {
            title: "Spouse Self-Emplyoment Tax FICA",
            currencyResult: engineResults.selfEmployedFicaTax.spouse,
            explanationText:
              engineResults.selfEmployedFicaTax.spouse > 0
                ? engineResults.selfEmployedFicaTaxExplanation.spouse
                : "",
            hide:
              filingStatusCode !== "mfj" ||
              !spouseSelfEmployment ||
              engineResults.selfEmployedFicaTax.spouse === 0,
          },
          {
            title: "Spouse Self-Emplyoment Tax Medicare",
            currencyResult: engineResults.selfEmployedMedicareTax.spouse,
            explanationText:
              engineResults.selfEmployedMedicareTax.spouse > 0
                ? engineResults.selfEmployedMedicareTaxExplanation.spouse
                : "",
            hide:
              filingStatusCode !== "mfj" ||
              !spouseSelfEmployment ||
              engineResults.selfEmployedMedicareTax.spouse === 0,
          },
          {
            title: "LTCG & QD Taxes",
            currencyResult: engineResults.capitalGainsAndDividendsTax,
            explanationText:
              engineResults.capitalGainsAndDividendsTax > 0
                ? engineResults.capitalGainsAndDividendsTaxExplanation
                : "",
            hide: engineResults.capitalGainsAndDividendsTax === 0,
          },
          {
            title: "Estimated Total Taxes",
            currencyResult: engineResults.estimatedTotalTaxes,
            boldTitle: true,
          },
        ]}
      />
      <Row style={{ marginTop: 25 }}>
        <Col span={24}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              type="success"
              onClick={() => {
                save();
                onDismiss && onDismiss("saved");
              }}
            >
              Save
            </Button>
            {modal && (
              <Button
                type="default"
                style={{ marginLeft: 10 }}
                onClick={() => onDismiss!("cancelled")!}
              >
                Cancel
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </CalculatorShell>
  );
};

export default FederalTaxEngineCalculator;
