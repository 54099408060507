import { Modal, Typography } from "antd";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getVideoByCode, getVideoById } from "./utils";

const Video = styled.div`
  position: relative;
  padding-bottom: 46.25%;
  margin-top: -12px;
  height: 0;
  overflow: hidden;
  user-select: none;

  video {
    border: 3px solid black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

type Props = {
  videoId?: string;
};

const VideoViewer: React.FC<Props> = ({ videoId }) => {
  let { code } = useParams<{ code: string }>();
  const videoById = videoId ? getVideoById(videoId) : null
  const videoByCode = code ? getVideoByCode(code) : null
  const video = videoById || videoByCode

  if (!video) {
    return null;
  }

  const { title, link } = video
  document.title = title;

  return (
    <Modal
      visible
      style={{
        textAlign: "center",
        userSelect: "none",
      }}
      width="85%"
      footer={null}
      closable={false}
      centered
    >
      <Typography.Title level={3} style={{ marginBottom: 24 }}>
        {title}
      </Typography.Title>
      <Video>
        <video autoPlay={false} controls src={`../videos/${link}`} />
      </Video>
    </Modal>
  );
};

export default VideoViewer;
