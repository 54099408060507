import { Card } from "antd";
import { PropsWithChildren } from "react";
import SmartTitle from "../../../components/smart-title";

type Props = PropsWithChildren<{
  title: string;
  titleSmall?: string;
  hideHomeLink?: boolean;
  icon?: JSX.Element
}>;

const CalculatorShell: React.FC<Props> = ({
  title,
  titleSmall,
  hideHomeLink,
  icon,
  children,
}) => {
  return (
    <Card style={{ border: hideHomeLink ? "none" : "inherit" }}>
      <SmartTitle
        title={title}
        titleSmall={titleSmall}
        hideHomeLink={hideHomeLink}
        icon={icon}
      />
      {children}
    </Card>
  );
};

export default CalculatorShell;
