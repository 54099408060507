export type IncomeReductionType =
  | "C-Corporation"
  | "S-Corporation"
  | "Self-employment Income"
  | "Passive Income";

const incomeReductionType: Array<IncomeReductionType> = [
  "C-Corporation",
  "S-Corporation",
  "Self-employment Income",
  "Passive Income",
];

export { incomeReductionType };
