import { CURRENT_YEAR } from "./default-values";
import { TFilingStatusCode } from "./filing-status";

export type TMedicareTaxTriggerYear = {
  year: number;
  threshold: number;
};

type TMedicareTaxTriggers = {
  filingStatusCodes: Array<TFilingStatusCode>;
  years: Array<TMedicareTaxTriggerYear>;
};

const single: TMedicareTaxTriggers = {
  filingStatusCodes: ["s"],
  years: [
    { year: 2021, threshold: 200000 },
    { year: 2022, threshold: 200000 },
  ],
};

const marriedFilingSeperately: TMedicareTaxTriggers = {
  filingStatusCodes: ["mfs"],
  years: [
    { year: 2021, threshold: 125000 },
    { year: 2022, threshold: 125000 },
  ],
};

const marriedFilingJointly: TMedicareTaxTriggers = {
  filingStatusCodes: ["mfj"],
  years: [
    { year: 2021, threshold: 250000 },
    { year: 2022, threshold: 250000 },
  ],
};

const headOfHouseholdQualifiedWidow: TMedicareTaxTriggers = {
  filingStatusCodes: ["hh", "qw"],
  years: [
    { year: 2021, threshold: 200000 },
    { year: 2022, threshold: 200000 },
  ],
};

const brackets = [
  single,
  marriedFilingSeperately,
  marriedFilingJointly,
  headOfHouseholdQualifiedWidow,
];

const getAdditionalMedicareTax = (
  filingStatusCode: TFilingStatusCode,
  filingYear: number = CURRENT_YEAR,
  wages: number,
  selfEmploymentIncome: number
) => {
  const filingStatusBrackets = brackets.find((b) =>
    b.filingStatusCodes.includes(filingStatusCode)
  )!;
  const { threshold } = filingStatusBrackets.years.find(
    (y) => y.year === filingYear
  )!;
  return {
    amount: Math.max((wages + selfEmploymentIncome - threshold) * 0.009, 0),
    explanation: `Calculated as total wages of $${wages.toLocaleString()} + self-employment income of $${selfEmploymentIncome.toLocaleString()} less the trigger threshold for ${filingYear} of $${threshold.toLocaleString()} multiplied by 0.9%`,
  };
};

export { getAdditionalMedicareTax };
